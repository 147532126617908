<template>
  <div>
    <Loading :loading="loading" />

    <h4 class="title is-4">Developer Tools</h4>
    <AccountStatus v-if="user" :user="user" />
    <ApiSettings :user="user" @init="$emit('init')" />
    <WebhookSettings :user="user" @init="$emit('init')" />

    <FlashMessage :flash="flash" />
  </div>
</template>

<script>
import { api } from '@/modules/util'

export default {
  name: 'Developer',
  props: ['user'],
  data() {
    return {
      loading: true,
      apiKey: '',
      apiVersion: null,
      recentlySent: [],
      recentlyReceived: [],
      flash: [],
    }
  },

  methods: {
    async init() {
      try {
        this.loading = true
        const res = await api.get('/users/developer') // TODO redo this route
        this.recentlySent = res.data.recentlySent
        this.recentlyReceived = res.data.recentlyReceived
        this.loading = false
      } catch (err) {
        this.$emit('init')
        this.$router.push({ name: 'Login' })
      }
    },
  },
  mounted() {
    this.init()
  },
}
</script>
